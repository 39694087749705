.management_sidebar {
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.management_sidebar::-webkit-scrollbar {
  display: none;
}

.ellipsis{
  text-overflow:ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.management_sidebar .list_group {
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0px 15px;
}

.management_sidebar .list_group_item {
  border-color: transparent !important;
  padding: 8px 0px;
  font: 600 14px Spartan;
  color: #2d2424;
  margin-top: 15px;
  text-decoration: none;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
  position: relative;
}
.sb_arrow{
  position: absolute;
  right: 10px;
  top: 15px;
}

.management_sidebar .list_group_item.active {
  background-color: transparent;
}

.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}

.management_sidebar .list_group_item:hover,
.management_sidebar .list_group_item.menu_active {
  color: #9a8776;
  background: transparent linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-left: 5px solid #9a8776 !important;
}

.management_sidebar .list_group_item:hover .sb_image,
.management_sidebar .list_group_item.menu_active .sb_image {
  display: none;
}

.management_sidebar .list_group_item:hover .sb_image_active,
.management_sidebar .list_group_item.menu_active .sb_image_active {
  display: inline-block !important;
}

.management_sidebar .list_group_sub_item.menu_active {
  color: #9a8776;
  background: transparent linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
}

.management_sidebar .list_group_item .sb_image,
.management_sidebar .list_group_item .sb_image_active {
  height: 20px;
  width: 20px;
  margin: 0px 20px;
  vertical-align: sub;
}

.sb_image_active {
  display: none;
}

.management_sidebar .list_group_sub_item {
  /* padding-left: 90px !important; */
  border-color: transparent !important;
  padding: 8px 10px 8px 22px;
  color: #2d2424;
  text-decoration: none;
  font: 500 14px Spartan;
  border-left: 5px solid;
  border-radius: 0px 5px 5px 0px;
}

.list_group_flush {
  margin-left: 35px;
  border-left: 1px solid #D5D5D8;
}

.management_sidebar .list_group_sub_item:hover {
  color: #9a8776;
  background: transparent linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  /* box-shadow: 0px 15px 30px #9A877633; */
  /* border-left: 5px solid #9a8776 !important; */
}

.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}

.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3f4544;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3f4544;
  display: block;
}

.side_menu_img {
  background-color: red;
}

.logo{
  text-align: center;
  margin-top: 40px;
}

.logo img{
  height: 50px;
  width: 150px;
  object-fit: contain;
}