.error{
    color: #FF7575;
    font: 500 13px/19px "Spartan";
    text-align: end;
    display: block;
}

/* Login Modal */
.custom_modal .modal-dialog{
  width: 100%;
}
.custom_modal .modal-content{
  border-radius: 0;
}
.custom_modal .modal-body{
  padding: 0;
  position: relative;
}
/* .login_modal_banner{
    height: 410px;
} */
.login_modal_banner_image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.login_modal_form{
    padding: 12px 47px 12px 09px !important;
    display: flex;
    flex-direction: column;
    min-height: 410px;
}
.login_modal_heading{
    font: bold 26px/34px "Cormorant Garamond"; 
    margin-bottom: 9px;
    color: #9A8776;
}
.login_modal_title{
    font: normal 12px/20px "Spartan"; 
    margin-bottom: 24px;
    color: #353B3B;
}
.login_modal_signup_nav{
    font: normal 12px/20px "Spartan"; 
    color: #9A8776;
    text-transform: uppercase;
    cursor: pointer;
}
.custom_modal_closebtn{
    position: absolute;
    top: 12px;
    right: 12px;
    border: 0;
    outline: 0;
    background: transparent;
    padding: 0;
    z-index: 1;
    line-height: 0;
}
.custom_modal_closebtn img{
    width: 11px;
    height: 11px;
}
.input_row{
    margin-bottom: 15px;
}
.input_field{
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px;
    border: 1px solid #DEDEDE;
    border-radius: 3px;
    background: #F6F7F8;
    margin-bottom: 0;
    position: relative;
}
.input_section{
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 25px;
    position: relative;
}
.input_field input, .input_field input:hover, .input_field input:focus{
    font: normal 10px/16px "Spartan"; 
    color: #9A9A9A;
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none !important;
    background: transparent;
    width: 100%;
}
.password_icon, .login_btn_arrow{
    line-height: 0;
    cursor: pointer;
}
.password_icon img, .login_btn_arrow img{
    width: 100%;
}
.password_icon{
    width: 10px;
}
.login_btn_arrow{
    width: 6.5px;
    position: absolute;
    right: 10px;
}
.other_options{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login_modal_text{
    font: normal 12px/16px "Spartan"; 
    color: #353B3B;
    margin-bottom: 23px;
}
.send_otp_section{
    display: flex;
    justify-content: space-between;
}
/* Login Modal */

/* Signup */
.form_split_section{
    margin-bottom: 21px;
}
.form_split_input_field{
    margin-bottom: 10px;
}
.verifyotp_btn{
    font: 500 10px/16px "Spartan"; 
    color: #9A8776;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: end;
    cursor: pointer;
}
.terms{
    font: 500 10px/16px "Spartan"; 
    color: #737373;
    margin-bottom: 6px;
}
.terms span{
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
    cursor: pointer;
}

.otp_msg span{
    text-align: left;
    text-decoration: underline;
    font: normal normal medium 12px/16px Spartan;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 15px;
}
.otp_msg_verify{
    margin-bottom: 15px;
}
.otp_login_btn button{
    width: 100%;
    height:49px;
    border-radius: 3px;
    font: 400 16px/20px Spartan;
}
/* Signup */

/* Verify OTP */
.otp_input_section{
    margin: 0 0 14px;
    position: relative;
}
.otp_input_section > div{
    display: flex;
    justify-content: space-between;
}
.otp_input_field input{
    border: 1px solid #DEDEDE;
    border-radius: 1px;
    background: #F6F7F8;
    width: 42px !important;
    height: 44px;
}
.otp_input_field_admin input{
    width: 46px !important;
    height: 43px;
}
.otp_input_field > span{
    display: none;
}
/* Verify OTP */

/* Admin Login */
.admin_login_banner{
    padding: 0;
    height: 100vh;
    min-height: 500px;
}
.admin_login_banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.main_logo{
    margin-bottom: 0;
    line-height: 0;
}
.main_logo a{
    display: inline-block;
}
.main_logo img{
    width: 149px;
}
.admin_login_content{
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    height: 100vh;
    min-height: 500px;
}
.admin_login_content form{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width:480px;
    margin: 0 auto;
}
.admin_login_heading{
    font: 600 20px/34px "Spartan"; 
    color: #fafcfc;
    margin-bottom: 25px;
}
.admin_forgot_heading{
    font: 600 20px/34px "Spartan"; 
    color: #192525;
    margin-bottom: 25px;
}
.admin_input_field{
    height: 50px;
    padding: 0 25px;
    border-radius: 3px;
    max-width: 480px;
}
.admin_input_field input, .admin_input_field input:hover, .admin_input_field input:focus{
    font: normal 16px/24px "Spartan";
    color: #9A9A9A;
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none !important;
    background: transparent;
    width: 100%;
    max-width: 480px;
}
.back_to_login{
    text-align: right;
    font: normal normal 12px/16px Spartan;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 24px;
}
.otp_msg_admin{
    text-align: left;
    text-decoration: underline;
    font: normal normal 12px/16px Spartan;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 19px;
}
.otp_msg_admin span{
    text-transform: none;
}
.forgot_password{
    color: #FFFFFF;
}
/* Admin Login */
/* Forgot Password */
.close{
border: none !important;
background: none;
height:31px ;
display: flex;
margin-left: auto;
font-size: 24px;
opacity: 1;
}
.close_btn{
    font-size: 30px;
    color: #192525;
    text-align: left;
    position: absolute;
    top: 0;
    right: 10px;
}
.modal_title{
    text-align: center;
    color: #353B3B;
    font:  600 20px Spartan ;
}
.sub_text{
margin-bottom: 20px;
text-align: center;
color: #747474;
font: 300 16px Spartan;
}
.forgot_btn{
width: 100%;
height: 40px;
color: #A39486;
border-radius: 8px 8px 8px 8px;
margin-bottom: 10px;
}
/*End Forgot Password */

@media (max-width: 991px) and (min-width: 768px){
    .admin_login_content {
        padding: 30px;
    }
    .otp_input_field_admin input{
        width: 46px !important;
        height: 43px;
    }
}

@media (max-width: 767px){
    .login_modal_banner{
        display: none;
    }
    .login_modal_form {
        padding: 12px 46px 12px 39px !important;
    }
    .admin_login_banner{
        display: none;
    }
    .otp_input_field_admin input{
        width: 46px !important;
        height: 43px;
    }
}
@media(max-width: 480px){
    .admin_login_content{
        padding: 30px 20px;
    }
    .otp_input_field_admin input{
        width: 46px !important;
        height: 43px;
    }
}
.eyeIcon{
    cursor: pointer;
}