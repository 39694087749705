.management_table table {
    border-collapse: separate;
    border-spacing: 0px 15px;
  }
  .management_table table th {
    font: 400 12px/20px Spartan;
    color: #2D2424;
    white-space: nowrap;
  }
  .management_table table th:last-child {
    text-align: center;
  }
  .management_table table tbody tr {
    background-color: #fff;
  }
  .management_table table tbody td {
    font: 600 10px/20px Spartan;
    color: #2D2424;
    vertical-align: middle !important;
    white-space: nowrap;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .management_table table tbody td .admin_table_edit_img {
    height: 22px;
    width: 22px;
  }
  .management_table table tbody td:first-child {
    border-radius: 15px 0 0 15px;
    padding-left: 30px !important;
  }
  .management_table table tbody td:last-child {
    border-radius: 0 15px 15px 0;
    text-align: center;
  }
  .title_text_value {
    font: 500 12px/20px Spartan;
    color: #9A8776;
  }
  .title_h4 {
    font: 600 16px/20px Spartan;
    color: #2E2E2E;
    margin-bottom: 12px;
  }
  .management_header_downloads_icon {
    margin-left: 10px;
  }
  .management_header_downloads_text {
    margin-left: 10px;
    font: 600 10px/18px Spartan;
    color: #2D2424;
  }
  