.form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .form_control {
    border: 1px solid #CDD0E34B;
    padding: 5px 15px;
    border-radius: 10px;
    font: 500 14px/20px Spartan;
    color: #2E2E2E;
  }
  
  .btn {
    font: 600 12px Spartan;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
  }
  .btn img {
    vertical-align: bottom;
    margin-right: 5px;
  }
  
  .btn:focus {
    box-shadow: none !important;
  }
  
  .btn_borderd {
    color: #9A8776;
    background-color: transparent;
  }
  .btn_borderd:hover {
    color: #fff;
    background-color: #9A8776;
  }
  
  .btn_filled {
    color: #fff !important;
    background-color: #9A8776 !important;
  }
  .btn_filled:hover {
    text-decoration: underline;
  }
  
  .secondry_title {
    font: 500 12px/20px Spartan;
    color: #3F4544;
    margin-bottom: 12px !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
  }
  .management_contents .management_header h1 {
    font: 600 20px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_header h1 img {
    margin-right: 20px;
  }
  .management_contents .management_header .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    display: inline;
    width: auto;
    font-weight: 600 !important;
    font-size: 12px;
    color: #2D2424;
    padding: 5px 15px;
    height: auto;
  }
  
  .administrator {
    background-color: #ffffff;
    padding: 30px;
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    margin-bottom: 20px;
  }
  .administrator .administrator_title {
    font: 600 20px/33px Spartan;
    color: #3F4544;
    margin-bottom: 20px;
  }
  .administrator .administrator_description {
    font: 500 14px/24px Spartan;
    color: #3F4544;
    margin-bottom: 30px;
  }
  .administrator .card_accounts {
    border: 1px solid #8282824B;
    border-radius: 15px;
    background-color: #ffffff;
  }
  .administrator .card_accounts .card_accounts_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .administrator .card_accounts .card_accounts_header .card_accounts_text {
    font: 500 14px/24px Spartan;
    color: #747474;
  }
  .administrator .card_accounts .card_accounts_header .card_accounts_img img {
    height: 28px;
    width: 28px;
    border: 2px solid white;
    border-radius: 50%;
  }
  .administrator .card_accounts .card_accounts_header .card_accounts_img img:not(:first-child) {
    margin-left: -12px;
  }
  .administrator .card_accounts .card_title {
    font: 600 20px/24px Spartan;
    color: #3F4544;
    margin-bottom: 15px;
  }
  .administrator .card_accounts .card_link {
    font: 500 14px/24px Spartan;
    color: #2797F8;
  }
  .administrator .card_accounts .card_link:hover {
    text-decoration: underline;
  }
  .administrator .administrator_table .administrator_table_tabs {
    border-bottom: 1px solid #EBEBEB;
  }
  .administrator .administrator_table .administrator_table_tabs .administrator_table_tabs_text {
    padding-bottom: 10px;
    display: inline-block;
    margin-right: 40px;
    font: 600 14px/24px Spartan;
    color: #3F4544;
  }
  .administrator .administrator_table .administrator_table_tabs .administrator_table_tabs_text.active {
    color: #9A8776;
    border-bottom: 5px solid #9A8776;
    padding-bottom: 10px;
    margin-bottom: -3px;
  }
  .administrator .administrator_table .management_table table {
    border-collapse: separate;
    border-spacing: 1px 15px;
  }
  .administrator .administrator_table .management_table table th {
    font: 400 12px/20px Spartan;
    color: #2D2424;
    white-space: nowrap;
  }
  .administrator .administrator_table .management_table table tbody tr {
    /* background-color: #fff; */
    box-shadow: 0 0 0 1px #cdd0e34b !important;
    border-radius: 15px;
  }
  .administrator .administrator_table .management_table table tbody td {
    font: 600 10px/20px Spartan;
    color: #2D2424;
    vertical-align: middle !important;
    white-space: nowrap;
  }
  .administrator .administrator_table .management_table table tbody td .admin_table_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .administrator .administrator_table .management_table table tbody td .admin_table_edit_img {
    height: 22px;
    width: 22px;
  }
  .administrator .administrator_table .management_table table tbody td:first-child {
    border-radius: 15px 0 0 15px;
  }
  .administrator .administrator_table .management_table table tbody td:last-child {
    border-radius: 0 15px 15px 0;
  }/*# sourceMappingURL=index.css.map */
  .sitesetting_list_bg{
    background: transparent;
    padding: 30px 0px;
  }
  .sitesetting_list_bg tr{
    background: #fff;
  }
  .sitesetting_list_bg thead tr{
    background: transparent;
  }