html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

input:focus {
  outline: none !important;
}

/* input::-webkit-calendar-picker-indicator {
  cursor: pointer;
} */
.spinner_btn {
  margin-right: 10px !important;

}

select {
  cursor: pointer !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.btn-link {
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

/* Login Modal */
.custom_modal .modal-dialog {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: 576px;
}

.custom_content { 
  width: 500px !important;
}

.confirm_message {
  white-space: break-spaces;
  text-align: center;
}

.custom_dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content.custom_content{
  width: fit-content !important;
}
.custom_modal .modal-content {
  border-radius: 0;
}

.modal-body {
  align-self: center;
  padding: 1rem 1rem 0rem 1rem !important;
}

.modal_cancel_button {
  top: 415px;
  left: 561px;
  width: 90px;
  height: 48px;
  border: 1px solid #9A8776;
  border-radius: 5px;
}

.modal_success_button {
  top: 415px;
  left: 693px;
  width: 90px;
  height: 48px;
  background: #9A8776 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

.custom_modal .modal-body {
  padding: 0;
  position: relative;
}

.forgot_form_modal .modal-dialog {
  margin-top: 200px !important;
  text-align: center;
  width: 459px !important;
  height: 268px !important;
  border-radius: 11px;
  opacity: 1;
}

.forgot_btn {
  width: 410px;
  height: 50px;
  color: #A39486;
  border-radius: 8px 8px 8px 8px;
}

.login_btn,
.login_btn:hover,
.login_btn:focus {
  font: normal 12px/34px "Spartan";
  border-radius: 3px;
  background: #353b3b;
  color: #ffffff;
  text-transform: uppercase;
  min-height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  outline: none;
  position: relative;
  margin-bottom: 12px;
  outline: none;
  box-shadow: none;
}

.login_btn_admin,
.login_btn_admin:hover,
.login_btn_admin:focus {
  font: normal 20px/28px "Spartan";
  min-height: 58px;
  border-radius: 3px;
  max-width: 480px;
}


.forgetpassword_btn,
.forgetpassword_btn:hover,
.forgetpassword_btn:focus {
  font: normal 10px/16px "Spartan";
  padding: 0;
  text-decoration: underline;
  color: #9a8776;
  border: none;
  outline: none;
  background: transparent;
}

button.login_otp,
button.login_otp:hover,
button.login_otp:focus {
  font: normal 10px/16px "Spartan";
  padding: 0;
  color: #9a8776;
  text-transform: uppercase;
  border: 0;
  outline: 0;
  box-shadow: none !important;
  background: transparent;
  white-space: nowrap;
}

.social_login_options {
  margin-top: auto;
  font: normal 8px/10px "Spartan";
  color: #9a9a9a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_login_options>* {
  margin: 0 4px;
}

.social_login_btn,
.social_login_btn:hover,
.social_login_btn:focus {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  border-radius: 12px;
  width: 23px;
}

.social_login_btn img {
  width: 100%;
}

.proceed_btn {
  font: normal 12px/16px "Spartan";
  color: #ffffff;
  border-radius: 3px;
  padding: 0 18px;
  min-height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #353b3b;
  border: none;
  outline: none;
}

.disabled {
  cursor: not-allowed;
}

/* Login Modal */

/* Logout */
.logout_btn,
.logout_btn:hover,
.logout_btn:focus {
  font: normal 20px/28px "Spartan";
  min-height: 58px;
  border-radius: 3px;
  background: #353b3b;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  position: relative;
  margin: 15px 15px 15px auto;
  outline: none;
  box-shadow: none;
}

/* Logout */

/* Ant Alert */
.ant-alert {
  align-items: flex-start !important;
  margin-bottom: 10px !important;
}

.anticon {
  margin-top: 4px;
}

/* Ant Alert */

.mt-32 {
  margin-top: 32px;
}

.cursor-pointer {
  cursor: pointer;
}

.theme-btn {
  font: 600 12px/20px Spartan;
  background-color: #9a8776;
  border-radius: 7px;
  border: 1px solid #9a8776;
  color: #fff;
  /* border-color: #9a8776; */
  transition: background-color border 100ms ease;
  padding: 6px 22px;
  /* cursor: pointer; */
}

.theme-btn.rnd {
  border-radius: 7px;
}
.theme-btn.cnc {
  color: #9a8776;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.theme-btn.cnc:hover {
  color: #9a8776;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: underline;
}

.theme-btn:hover,
.theme-btn:active {
  background-color: #747474;
  border-color: #747474;
  transition: background-color 200ms ease;
}

.theme-btn:disabled {
  background-color: #dddddd;
  border-color: #dddddd;
  cursor: default;
}

.theme-btn-link {
  font: 500 12px/20px Spartan;
  cursor: pointer;
  color: #9a8776;
  text-decoration: underline;
  transition: color 100ms ease;
}

.theme-btn-link:hover {
  color: #747474;
  transition: color 150ms ease;
}

.theme-btn-outlined {
  font: normal normal 500 14px/20px Spartan;
  color: #A39486;
  background-color: transparent;
  border-radius: 7px !important;
  border-color: #9a8776;
  transition: color border-color 100ms ease;
  padding: 10px 10px;
  cursor: pointer;
}

.theme-btn-outlined:hover {
  font: normal normal 500 14px/20px Spartan;
  color: #A39486;
  background-color: transparent;
  border-radius: 7px !important;
  border-color: #9a8776;
  transition: color border-color 150ms ease;
}

.theme-form-control {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  font-family: "Spartan", inherit !important;
}

.theme-form-control:focus {
  box-shadow: none;
  border-color: #cdd0e34b !important;
  color: #495057;
  background-color: #fff;
}

.theme-form-control.with-icon {
  padding-left: 35px;
}

.theme-form-control.rnd {
  border-radius: 7px !important;
}

.theme-error-input,
.theme-error-input:focus,
.theme-error-blk {
  border-color: #ff7575 !important;
  box-shadow: 0 0 3px 1px #ff7575;
}
.theme-error-input,
.theme-error-input:focus,
.theme-error-blk-space{
  border-color: #ff7575 !important;
  box-shadow: 0 0 3px 1px #ff7575;
}
.theme-txt-xsm {
  font-family: "Spartan";
  font-size: 10px;
}

.theme-txt-sm {
  font-family: "Spartan";
  font-size: 12px;
}

.theme-txt-detail {
  font-family: "Spartan";
  font-size: 14px;
  white-space: break-spaces;
  text-align: center;
}

.theme-txt {
  font-family: "Spartan";
  font-size: 16px;
  line-height: 18px;
}

.theme-txt-disabled,
option:disabled {
  color: #dadada;
}

.theme-txt-subtitle {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}

.theme-form-section-title {
  font: 600 16px/20px Spartan;
  color: #2e2e2e;
}

.theme-form-label {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}

.theme-txt-title {
  font-size: 24px;
  line-height: 28px;
}

.theme-txt-main-title {
  font-size: 36px;
  line-height: 72px;
}

.theme-error-txt {
  color: #ff7575 !important;
}

.ant-spin-dot-item {
  background-color: #9a8776;
}

.product-tabsbox .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  background: #9A877633 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #9A877633;
  border-radius: 6px 6px 0px 0px;
  color: #A39486;
  font-size: 13px;
}

.product-tabsbox .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  background: #A39486;
}

.product-tabsbox .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;

}
span.ant-upload-list-item-actions {
  display: none;
}
button.outline_cancel_btn {
  background: transparent;
  border-radius: 7px !important;
  color: #A39486;
  border: 1px solid #A39486;
}
button.outline_cancel_btn:hover {
  background: #A39486;
  color: #fff;
}
button.outline_btn {
  background: transparent;
  border-radius: 7px !important;
  color: #A39486;
  border: 1px solid #A39486;
  padding: 10px;
  font: normal normal 500 14px/20px Spartan;
}
button.outline_btn.no_outline_btn{
border:none;
font-size: 14px;
padding: 5px 15px;
}
button.outline_btn:hover {
  background: #A39486;
  color: #fff;
}
button.outline_btn.no_outline_btn:hover{
  background: transparent;
  color: #A39486;
}
.banner_region_select .ant-select-selection-overflow-item{
margin-top: 0px;
}
.outline_btn_add{
  background: transparent!important;
  color: #9a8776!important;
  border-radius: 7px!important;
  padding: 12px 20px!important;
  line-height: 17px;
  margin-left: 0!important;
  font: normal normal 500 13px/20px Spartan !important;
  }
  .no_bottom_broder{
    border-bottom: 0!important;
  }
  .br-b-0{
    border-radius: 7px 7px 0 0!important;
  }
  .border-dote{
    border: 1px dashed #CDD0E3;
    border-radius: 5px;
    padding: 10px 15px;
  }
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 7px;
}
.add_city_btn{
  font: normal normal 600 12px/20px Spartan;
letter-spacing: 0px;
color: #C1A07F;
cursor: pointer;
}
.ant-switch{
  background-color: rgb(221 0 0);
}
.ant-switch-checked{
  background-color: #2E8E1D;
}
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #9a8776!important;
  border-color: #A39486!important;
}
.abandone_edit_notepad .rdw-editor-main{
min-height: 200px;
background-color: #EDEDF3;
padding:10px 20px;
border-radius: 11px;
}
.landing_select_city{
  align-items: center;
}
.landing_select_city .form-control{
background-color: transparent;
border: none!important;
padding-left: 0!important;
}
.landing_select_city .ant-select-selector{
min-width: 220px
}
.landing_select_city .ant-select-arrow{
  right: 20px;
}
.landing_select_city .error_msg_right{
  position:relative;
}
.landing_select_city .region_selector select{
  background-color: #fff;
}
.landing_select_city .error_msg_right .theme-error-txt{
right: 10px;
top: 35px;
position:absolute;
}
.region_selector{
  position: relative;
}
.landing_select_city .region_selector .theme-error-txt{
position:absolute;
top: 30px;
right: 0;
}
.landing_select_city .product_wise_text-danger{
position: relative;
top:-5px;
margin-right: 2px;
}
.rich_text_hidden .ck-editor label {
display: none!important;
}
.slug_input_white_bg input{
background: #fff!important;
}
@media (min-width: 768px) {
  .custom_modal .modal-dialog {
    max-width: 720px;
  }
}

@media (max-width: 7687px) {
  .custom_modal .modal-content {
    margin: 0 15px;
  }
}

.customCheck .custom-control-label::after {
  cursor: pointer !important;
}

.custom-control-label::after {
  cursor: pointer !important;
}

.custom-control-label span {
  cursor: pointer !important;
}

.password {
  position: absolute;
  right: 23px;
  top: 38px;
}

.wrapper {
  display: flex;
  width: 100%;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-top-left-radius: 40px;
}

.btn:focus {
  box-shadow: none !important;
}
#sidebar {
  position: relative;
  min-width: 260px;
  max-width: 260px;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  border-left: 1px solid #E4E4E4;
  padding: 30px;
  margin-left: 40px;
  background: #f6f6f5;
}

#sidebar #sidebarCollapse {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #EAEAEB;
  padding: 3px 12px;
  position: absolute;
  top: 45%;
  left: -15px;
}

#sidebar #sidebarCollapse img {
  padding-bottom: 3px;
  height: 15px;
  width: 6px;
  object-fit: cover;
}

#sidebar #sidebarCollapse .arrow_active {
  display: none;
}

#sidebar #sidebarCollapse .arrow {
  display: inline-block;
}

#sidebar.active #sidebarCollapse .arrow_active {
  display: inline-block;
}

#sidebar.active #sidebarCollapse .arrow {
  display: none;
}

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}

/* Shrinking the sidebar from 250px to 80px and center aligining its content*/
#sidebar.active {
  min-width: 120px;
  max-width: 120px;
  text-align: center;
}

#sidebar.active .dropdown-toggle{
  width: 70% !important;
}

#sidebar.active .dropdown .dropdown-toggle::after{
  right: 45px !important;
}

/* Toggling the sidebar header content, hide the big heading [h3] and showing the small heading [strong] and vice versa*/
#sidebar .sidebar-header strong {
  display: none;
}

#sidebar.active .sidebar-header h3 {
  display: none;
}

#sidebar.active .sidebar-header strong {
  display: block;
}

#sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 20px 10px;
  text-align: center;
  font-size: 0.85em;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

/* Same dropdown links padding*/
#sidebar.active ul ul a {
  padding: 10px !important;
}

/* Changing the arrow position to bottom center position, 
 translateX(50%) works with right: 50% 
 to accurately  center the arrow */
#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

/* Sidebar Activities */
#sidebar .dropdown-toggle {
  font: 500 14px/30px "Spartan";
  color: #000;
  background-color: #fff;
  border-color: #fff;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
}

#sidebar .activites {
  box-shadow: 0px 20px 30px #8C8C8C1A;
}

#sidebar.active .activites {
  box-shadow: none !important;
}

#sidebar .dropdown-toggle img {
  margin-right: 15px;
  vertical-align: middle;
}

#sidebar .dropdown-toggle::after {
  margin-left: 57px;
}

#sidebar .dropdown-menu {
  transform: translate(0px, 55px) !important;
  width: 100%;
  max-width: 200px;
  border: none !important;
  box-shadow: 0px 20px 30px #8C8C8C1A;
}

#sidebar .dropdown-menu .dropdown-item {
  padding: 10px !important;
  font: 500 14px/30px "Spartan";
}

.dropdown-item.active, .dropdown-item:active{
  font-weight: 600 !important;
  color: #9a8776 !important;
  background: transparent linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%) !important;
}
.dropdown-item .active_img{
  display: none;
}
.dropdown-item.active .inactive_img, .dropdown-item:active .inactive_img{
  display: none;
}
.dropdown-item.active .active_img, .dropdown-item:active .active_img{
  display: inline-block;
}
#sidebar .dropdown-menu .dropdown-item img {
  vertical-align: sub;
  margin-right: 12px;
}

#sidebar .text_hide {
  display: unset !important;
}

#sidebar.active .text_hide {
  display: none !important;
}

#sidebar.active .dropdown-toggle::after {
  margin-left: 0px;
}

#sidebar.active {
  padding: 30px 10px 0 !important;
  margin-left: 40px;
}

#sidebar.active .dropdown-menu {
  max-width: 70px !important;
  min-width: 70px !important;
  inset: 0px 0px auto 15px !important;
}

#sidebar.active .dropdown-menu .dropdown-item {
  text-align: center !important;
}

#sidebar.active .dropdown-menu .dropdown-item img {
  margin-right: 0px !important;
}

#sidebar.active .dropdown-menu .dropdown-item {
  padding: 7px !important;
}

#sidebar .dropdown .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 20px;
}

#sidebar .dropdown .btn {
  text-align: left;
}

#sidebar .activites .dropdown {
  width: 100%;
}

/* Sidebar Activities End*/
.card-box {
  border: 1px solid #CDD0E34B;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 25px;
}

.form_add_select .ant-select-selector {
  height: inherit;
  padding: 0;
  border-radius: 0.3rem !important;
}

.form_add_select .ant-select-multiple .ant-select-selection-item {
  background: #DEDEDE !important;
}

.form_add_select .ant-select-multiple .ant-select-selection-item-content {
  font: normal normal medium 14px/13px Spartan !important;
  color: #2E2F3C !important;
}

button.tranpr_bgbtn {
  border: none !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
  color: #9a8776;
  font: normal normal 600 16px/20px Spartan;
  letter-spacing: 0px;
}

.tax_gst_btn .custom-control-input:checked~.custom-control-label::before {
  border-color: #9a8776 !important;
  background-color: #9a8776 !important;
}

.form_add_select .anticon {
  vertical-align: middle !important;
  margin-top: -2px !important;
  zoom: 1.3;
}

.ant-select-selection-overflow-item {
  margin: 5px;
}

/* Order Progressbar */
.recent_activites {
  display: block;
  text-align: left;
  padding: 10px 0px;
  width: 100%;
}

.recent_activites .recent_activites_header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.recent_activites .recent_activites_header h4 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0px;
}

.recent_activites .recent_activites_header .dots {
  color: black;
  font-size: 25px;
  vertical-align: middle;
  padding-bottom: 12px;
}

.recent_activites .recent_activites_values {
  width: 100%;
}

.recent_activites .recent_activites_values .recent_activites_details {
  width: 100%;
  min-height: 80px;
  height: 100%;
  max-height: 150px;
  display: flex;
  min-width: 100px;
  position: relative;
  z-index: 0;
}

.recent_activites .recent_activites_values:not(:last-child) .recent_activites_details:before {
  content: "";
  position: absolute;
  width: auto;
  height: 100%;
  border-left: 1px dashed #9a8776;
  top: 0px;
  left: 20px;
  z-index: -1;
}

/* .recent_activites .recent_activites_values .recent_activites_details.rm-line:before {
  border: none !important;
} */

/* .recent_activites .recent_activites_values .recent_activites_details.completed:before {
  border-top: 1px solid green !important;
} */

.recent_activites .recent_activites_values .recent_activites_details .recent_activites_img {
  height: 40px;
  width: 40px;
  /* padding: 5px; */
  /* border: 1px solid #DFDFDF;
  border-radius: 50%; */
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  box-sizing: border-box;
  margin-right: 10px;
}

.recent_activites .recent_activites_values img {
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
  width: 40px;
}

.recent_activites .recent_activites_values .recent_activites_name {
  font: 500 10px/16px Spartan;
  color: #C8AC92;
}

.recent_activites .recent_activites_values .recent_activites_status {
  font: 600 10px/16px Spartan;
  color: #2D2424;
}

.recent_activites .recent_activites_values .recent_activites_date {
  font: 500 10px/16px Spartan;
  color: #9F9C9C;
  margin-top: 5px;
  margin-bottom: 20px;
}

.store-timepicker .ant-picker {
  width: 100%;
}

.store-addimages h4 {
  font-size: 12px !important;
  color: #2e2e2e !important;
}

.recent_activites .recent_activites_values .recent_activites_date span {
  display: block;
}

#sidebar.active .recent_activites_values .recent_activites_details {
  justify-content: center;
}

#sidebar.active .recent_activites_values:not(:last-child) .recent_activites_details:before {
  left: 45px;
}
.dashboard-progressbar .CircularProgressbar .CircularProgressbar-trail{
  stroke: #f8040400;
}
.firstprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #C8AC92;
}
.firstprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #C8AC92;
}
.secondprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #BE6A4A;
}
.secondprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #BE6A4A;
}
.thirdprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #677B7B;
}
.thirdprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #677B7B;
}
.forthprogress-state-overview .CircularProgressbar .CircularProgressbar-path{
  stroke: #9F9F9B;
}
.forthprogress-state-overview .CircularProgressbar .CircularProgressbar-text{
  font-size: 16px;
  fill: #9F9F9B;
}
/* Order Progressbar End*/
.add_discount_form .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 7px;
}
.add_discountpost{
  float: right;
  border-radius: 7px!important;
  padding: 5px 20px!important;
}
.br-7{
  border-radius: 7px!important;
}
.banner_tab_btn button.active{
  background:#A39486!important;
  color: #fff;
}
/* Quick Details */
.quick_details {
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 18px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
}

.quick_details .quick_details_title {
  font: 600 12px/18px Spartan;
  color: #2D2424;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E8E9EC;
}

.quick_details .quick_details_contents {
  display: flex;
  align-items: flex-start;
}

.quick_details .quick_details_contents:not(:last-child) {
  margin-bottom: 20px;
}

.quick_details .quick_details_contents img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.quick_details .quick_details_contents .quick_details_contents_details .quick_details_contents_title {
  font: 600 10px/16px Spartan;
  color: #2D2424;
}

.quick_details .quick_details_contents .quick_details_contents_details .quick_details_contents_description {
  font: 400 10px/20px Spartan;
  color: #AEAEAE;
}

#sidebar.active .quick_details {
  max-width: 70px;
  margin: 15px auto;
}

/* Quick Details end */
/* Ant Table */
.ant-table {
  background-color: transparent !important;
}

.ant-table .ant-table-thead tr th {
  background-color: transparent !important;
  border-bottom: none !important
}

.ant-table .ant-table-thead .ant-table-cell::before {
  background-color: transparent !important;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: transparent !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 11px !important;
}

/* Ant Table End*/

.usermanage_table_filter_columns.ant-dropdown-link {
  display: inline-block;
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 12px/20px Spartan;
  cursor: pointer;
  white-space: nowrap;
}

.columns_list {
  padding: 15px 45px 15px 15px !important;
}

.columns_list ul {
  padding-left: 0px !important;
}

.columns_list ul li{
  white-space: nowrap;
}

.columns_list ul li span:last-child {
  padding-left: 10px !important;
  font: 600 12px/20px Spartan;
  /* position: relative; */
}

.columns_list ul li span:last-child img {
  position: absolute;
  top: 2px;
  /* right: 0px; */
  display: none;
  margin-left: 5px;
}

.columns_list ul li:hover span:last-child img {
  display: inline-block !important;
}

/* Action */
.action {
  display: inline-block;
  position: relative;
}

.action_header {
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 11px/20px Spartan;
  cursor: pointer;
  white-space: nowrap;
}

.action_header img {
  margin-left: 5px;
  width: 10px;
}

.action_contents {
  background-color: #ffffff;
  position: absolute;
  z-index: 1000;
  padding: 15px;
  margin-top: 2px;
  font: 600 12px/20px Spartan;
  box-shadow: 0px 10px 20px #00000033;
  border-radius: 8px;
  display: none;
  min-width: 260px;
}

.action:hover .action_contents {
  display: inline-block !important;
}

.action_contents span {
  display: block;
}

.action_contents .action_contents_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  height: 20px;
}

.action_contents_header_h2 {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.action_contents_header_h2_active {
  color: #9A8776;
}

.action_contents_header .action_contents_btn {
  border: 1px solid #9A8776;
  background-color: transparent;
  color: #9A8776;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 10px;
  text-decoration: none !important;
  cursor: pointer;
}

/* Action End*/

/* Filters */
.filter {
  display: inline-block;
  position: relative;
}

.filter:hover .card {
  display: inline-block !important;
}

.filter_heading {
  background-color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font: 500 12px/20px Spartan;
  cursor: pointer;
}

.card {
  width: 320px;
  position: relative !important;
  z-index: 1000;
  /* display: none; */
  margin-top: -4px;
}

.card_contents {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px #00000033;
  border-radius: 8px;
  padding: 10px;
  width: auto;
}

.card_info {
  font: normal normal normal 10px/20px Spartan;
  color: #AEAEAE;
}

.card_dropdown:nth-child(even) .card_dropdown_heading {
  margin-left: 10px;
}
.card_dropdown:nth-child(odd) .card_slect_hover{
  min-width: 150px!important;
}
.card_dropdown:nth-child(even) .card_slect_hover{
  min-width: 140px!important;
  margin-left: 10px;
}
.card_dropdown {
  min-width: 140px;
  margin-bottom: 10px;
}

.card_dropdown:not(:first-child) {
  display: none;
}

.show {
  display: inline-block !important;
}

.card_dropdown:hover .card_dropdown_contents {
  display: inline-block !important;
}

.card_dropdown_heading {
  border: 1px solid #E9E9F0;
  border-radius: 6px;
  position: relative;
  width: auto!important;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.filter_dropdown .card {
  border: none !important;
  flex-direction: row-reverse;
}


.ant-dropdown-menu {
  padding: 0;
  margin-top: 3px;
}

.filter_dropdown .card .card_contents .card_dropdown:nth-child(even) {
  min-width: 140px;
  /* margin-left: 10px; */
}

.filter_width .filter_dropdown .card .card_contents .card_dropdown .ant-picker-range{
  width: 230px !important;
}

.card_dropdown_heading img {
  position: absolute;
  right: 10px;
}

.card_dropdown_contents {
  border: 1px solid #E9E9F0;
  border-radius: 6px;
  padding: 5px 10px;
  min-width: 150px;
  max-height: 150px;
  overflow-y: auto;
  display: none;
  position: absolute;
  z-index: 1020;
  background-color: #ffffff;
  scrollbar-width: none;
}
.card_dropdown_contents::-webkit-scrollbar {
  width: 3px;
}
.card_dropdown_contents::-webkit-scrollbar-track {
  background: transparent;
}
.card_dropdown_contents::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.473);
}

.card_dropdown_contents span {
  display: block;
  font: 500 12px/17px Spartan;
}
/* .card_dropdown_contents.card_slect_hover{
  min-width: 130px;
  margin-left: 10px;
} */
.card_dropdown_contents.card_slect_hover span:hover{
  background: #9a8776; 
  color: #fff;
  cursor: pointer;
}

.card_dropdown_contents span:not(:last-child) {
  padding-bottom: 2px;
  padding-top: 2px;
}
.card_selct_checkbox input[type="checkbox"]{
position: relative;
top: -4px;
}

.parentCheckbox {
  position: relative;
  top: 49px;
  left: 11px;
  cursor: pointer;
  z-index: 9;
}

.filter_dropdown .form_control {
  /* margin-left: 10px; */
  padding: 10px 0px;
  border-radius: 6px !important;
  font: 500 12px/20px Spartan !important;
  width: 100%;
  border: 1px solid #E9E9F0 !important;
}

.filter_dropdown .card .card_contents .card_dropdown:nth-child(even) .form_control {
  margin-left: 10px;
  width: 90%;
}

.filter_dropdown .card .card_contents .card_dropdown .card_dropdown_contents label {
  margin-bottom: 0px !important;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  position: relative;
  top: 3px;
}
.filter_dropdown .card .card_contents .card_dropdown .card_dropdown_contents input {
 position: relative;
 margin-bottom: 0px !important;
 top: 0px;
}
.card_dropdown_no_padding{
  padding: 0;
  margin: 0;
}
.card_dropdown_contents.card_dropdown_no_padding span{
  line-height: 14px;
  padding: 5px 1px;
}
.export-icon{
  width: 13px;
  position: relative;
  top: -2px;
}
/* Filters end */

/* Order Reschedule Datepicker */
.order_events_comments_datepicker input {
  border: none !important;
  padding: 10px 50px 10px 20px !important;
}

.order_events_comments_datepicker .ant-picker-focused {
  border: none !important;
}

.order_events_comments_datepicker .ant-picker {
  border: none !important;
  border-radius: 8px !important;
  padding: 0px !important;
  cursor: pointer;
}

.order_events_comments_datepicker .ant-picker-suffix .anticon-calendar svg {
  display: none !important;
}

.order_events_comments_datepicker .ant-picker-suffix::before {
  /* content: 'Reschedule'; */
  font: 400 12px/16px Spartan;
  padding: 8px 10px 7px;
  border: 1px solid #A39486;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #A39486;
  color: #ffffff;
}

.ant-table-column-sorter {
  margin-top: -6px !important;
}

.datefilter.filter_dropdown .card_contents {
  padding: 0;
  width: 100px;
  background: transparent;
  box-shadow: none;
}

.datefilter.filter_dropdown .card_dropdown {
  margin-bottom: 0;
  top: -10px;
  right: 50px;
}

.datefilter.filter_dropdown .form-control {
  margin-left: 0;
  max-width: 145px;
}

.datefilter.filter_dropdown .card {
  width: 140px;
}

.acc_card_header .card-header {
  padding: 0 !important;
}
.ant_time_pick_input .ant-picker{
  width: 100%;
    border-radius: 4px;
    top: 3px;

}
.ant_time_pick_input .ant-picker-input > input{
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  padding: 1.5px;
}
.full_width{
  width:100%;
}
/* checkbox start*/

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #A39486!important;
}

 .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem #a394864d
}
 .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #a39486; 
}
.custom-control-input:checked ~ .custom-control-label::before{
  border-color: #a39486;
  background-color: #ab9d91;
}
/* checkbox start*/
/* Ant Tree */
.ant-tree {
  padding: 20px;
  border: 1px solid #CDD0E3;
  border-radius: 8px;
}

.ant-tree-title {
  font: 500 14px/13px Spartan;
  color: #2E2F3C;
}

.ant-tree-list {
  position: relative;
}

.ant-tree-checkbox {
  position: absolute;
  right: 0;
}

.ant-tree-switcher-noop~.ant-tree-checkbox {
  margin-right: 30px !important;
}

.ant-table-content {
  /* border: 1px solid #CDD0E3;
  border-radius: 8px;
  margin-top: 10px; */
}


/* CMS Page Tab Customization */
.content_tab .ant-tabs-tab{
  padding: 0px !important;
}

.content_tab .ant-tabs-tab .ant-tabs-tab-btn{
  font: 400 12px/13px Spartan !important;
  color: #A39486 !important;
  padding: 10px !important;
  background-color: #9A877633;
  border-radius: 6px 6px 0px 0px;
}

.content_tab .ant-tabs-tab + .ant-tabs-tab{
margin: 0 0 0 3px !important;
}

.content_tab .ant-tabs-ink-bar {
  background-color: transparent !important;
}

.content_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
  background-color: #A39486 !important;
}
/* CMS Page Tab Customization End*/
/* loader align */
.loader{
  text-align: center;
}

.graph-layer{
  overflow-x: auto;
}

.graph-layer::-webkit-scrollbar {
  width: 2px;
}
.right_slide_bar_sticky::-webkit-scrollbar-thumb {
  background-color: #e1e1dfd6; 
}

.truncate{
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.annotation-text{
  font-size: 18px !important;
}

.ant-table-column-sorters{
  justify-content: normal !important;
}

.ant-table-column-title{
  flex: none !important;
}
/* .ant-table-column-sorter {
  margin-left: -27px !important;
} */

/*Check box Background color*/
input[type="checkbox"]{
  accent-color: #9a8776;
}
select {
  font-family: "Spartan" !important;
}
select > * {
  font-size: 13px!important;
  font-family: "Spartan" !important;
}
select > option{
  font-family: "Spartan" !important;
}
.add_category_error_text{
  position: relative;
}
.add_category_error_text .theme-error-txt{
  position: absolute;
  top: 40px;  
  right: 0;
}

/* .ck-toolbar__items .ck-button:nth-child(5), */
.ck-toolbar__items .ck-button:nth-child(6),
.ck-toolbar__items .ck-button:nth-child(7),
.ck-toolbar__items .ck-button:nth-child(8),
.ck-toolbar__items .ck-button:nth-child(9),
.ck-file-dialog-button,
.ck-button.ck-disabled,
.ck-toolbar__items .ck-button.ck-disabled{
/* display:none!important; */
}

.ck-dropdown .ck-button{
display: none!important
}
  .ck-heading-dropdown .ck-button{
    display: block!important;
  }
.ck-toolbar__items span:not(.ck-toolbar__items span:nth-child(1)){
  background: none!important;
}
.br-10{
  border-radius: 10px!important;
}
.ant-switch-inner{
  font-weight:500!important;
}
.height-auto{
  height: auto!important;
}
.input_width_100{
  width: 100%!important;
}
.font_semibold{
  font-family: "Spartan" !important;
  font-weight:500!important;
}
.font_semibold .ant-select-selector{
  height: 36px!important;
}
.font_semibold.ant-select-single .ant-select-selector .ant-select-selection-item{
  line-height: 35px!important;
}
.antd_filter_dashboard{
  width: 140px;
}
.max-100{
  max-width: 100%!important;
}
.white_space{
  white-space: nowrap;
}
.top-10{
position: relative;
top: 9px;
}
.filter_ant_selecter .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  height: 28px;
}
.text-color-brown{
  font: normal normal 500 11px/14px Spartan!important;
  color: #5C3D2E!important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  font-family: Spartan!important;
}

.ant-select-multiple .ant-select-selection-placeholder{
  color: #000 !important;
}

.dashboard_filter_font .ant-select-single.ant-select-show-arrow .ant-select-selection-item{
  font-size: 12px;
  font-weight: 500;
  line-height: 33px;
}
.select_input_name .ant-select-multiple .ant-select-selection-item{
  top: -7px;
}
.ck-label {
  display: none !important;
}

.modal_header {
  align-self: center !important;
  border-bottom: none !important;
  padding: inherit !important;
}
 
.modal_bodyy {
  white-space: nowrap !important;
}
 
.modal_footer{
  justify-content: center !important;
border-top: none !important;  
}

.edit_category_update_user_date p{
  white-space: nowrap;
  font: normal normal 600 10px/20px Spartan;
  letter-spacing: 0px;
  color:#B9B9B9;
  margin-bottom: 3px;
  margin-left: 15px;
}
.edit_category_update_user_date span{
  color:#000;
}

.stock_reversal .ant-space{
  float: right !important;
 }

 /* new code */
 .productSelect{
  width: 100%;
  margin-bottom: 8px;
 }

 .productSelect .ant-select-selection-overflow{
    border: none;
    border-radius: 15px;
    min-height: 185px;
    align-content: start;
    padding: 10px;
}

.productSelect .ant-select-selection-placeholder{
  top: 18px !important; 
}

.custom-clear-all-btn{
  padding: 12px;
}