/* .management_sidebar */ .admin_sidebar_footer {
  margin: 0px 25px 25px;
}
/* .management_sidebar */ .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3f4544;
  margin-bottom: 0px !important;
}
/* .management_sidebar */ .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3f4544;
  display: block;
}
