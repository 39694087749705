.admin_management {
    outline: none !important;
  }
  .admin_management .row_margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .admin_management .row_margin .col-lg-2,
  .admin_management .row_margin .col-lg-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  
  .form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
  }
  
  .management_header_logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #fff;
  }
  .management_header_logo .management_info_img img {
    margin-left: 10px;
    height: 32px;
    width: 32px;
    box-shadow: 0px 5px 15px #0000001a;
    border-radius: 50%;
  }

 
  
  .management_sidebar .list_group {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .management_sidebar .list_group_item {
    border: none !important;
    padding: 8px 0px;
    font: 500 14px Spartan;
    color: #2D2424;
    margin-top: 15px;
  }
  .management_sidebar .list_group_item.active {
    background-color: transparent;
  }
  .management_sidebar .list_group_item.active img {
    box-sizing: content-box;
    padding: 10px;
    background-color: #f6efe7;
    border-radius: 4px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .management_sidebar .list_group_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .list_group_item img {
    height: 16px;
    width: 16px;
    margin: 0px 25px;
  }
  .management_sidebar .list_group_sub_item {
    padding-left: 90px !important;
    border: none !important;
    padding-top: 8px;
    padding-bottom: 8px;
    font: 500 14px Spartan;
  }
  .management_sidebar .list_group_sub_item:hover {
    color: #9A8776;
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-right: 5px solid #9a8776 !important;
  }
  .management_sidebar .admin_sidebar_footer {
    margin: 0px 25px;
  }
  .management_sidebar .admin_sidebar_footer h4 {
    font: 700 12px/30px Spartan;
    color: #3F4544;
    margin-bottom: 0px !important;
  }
  .management_sidebar .admin_sidebar_footer span {
    font: 500 8px/14px Spartan;
    color: #3F4544;
    display: block;
  }
  
  .management_contents {
    background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
    border-top-left-radius: 40px;
    padding: 50px;
    min-height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .management_contents .management_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8E8E8;
  }
  .management_contents .management_header h1 {
    font: 600 20px/33px Spartan;
    color: #3F4544;
  }
  .management_contents .management_header h1 img {
    margin-right: 20px;
  }
  .management_contents .management_header .form_control {
    border: 1px solid #CDD0E34B;
    border-radius: 46px;
    display: inline;
    width: auto;
    font-weight: 600 !important;
    font-size: 12px;
    color: #2D2424;
    padding: 5px 15px;
    height: auto;
  }
  .management_contents .management_contents_upload span {
    display: inline-block;
    font: 600 12px Spartan;
    color: #9A8776;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
    margin-left: 15px;
  }
  .management_contents .management_contents_upload span:last-child {
    color: #fff;
    background-color: #9A8776;
  }
  .management_contents .management_details .management_details_contents {
    margin-bottom: 10px;
  }
  .management_contents .management_details .management_details_contents h4 {
    font: 600 16px/20px Spartan;
    color: #2E2E2E;
    margin-bottom: 12px;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .management_contents .management_details .management_details_contents .management_details_contents_header a {
    font: 500 12px/20px Spartan;
    color: #4377BB;
  }
  .management_contents .management_details .management_details_contents .management_details_form {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
  }
  .management_contents .management_details.management_details_card_box .management_details_contents .management_details_form{
    border: none;
    border-radius: 0px;
    background-color: transparent;
    padding: 0px;
  }
  .tax_gst_btn .management_details_form{
    display: flex;
  }
 
  .tax_gst_btn .custom_radio{
    margin-right: 20px;
  }
  .taxdeletebtn button{
    background: transparent;
    font: normal normal 600 16px/20px Spartan;
    letter-spacing: 0px;
    color: #A39486;
    border:none;
    cursor: pointer;
  }
  .taxsave_btn button{
    border-radius: 5px!important;
  }
  .taxsave_btn button.tranpr_bgbtn{
    border: none;
    border-radius: 0px;
    background-color: transparent;
    padding: 0px;
  }
  .taxdeletebtn button:hover, .taxsave_btn .tranpr_bgbtn:hover{
    background: transparent;
  }
  .management_contents .management_details .management_details_contents .management_details_form .info_text {
    font: 500 12px/20px Spartan;
    color: #747474;
    margin-top: 10px;
    display: block;
  }
  .management_contents .management_details .management_details_contents .management_details_form .form_control {
    border: 1px solid #CDD0E3;
    border-radius: 10px;
    font: 500 14px/20px Spartan;
  }
  .management_contents .management_details .management_details_contents .management_details_form .border_bottom {
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
  .management_contents .management_details .management_details_contents .management_details_form label {
    font: 500 12px/20px Spartan;
    color: #2E2E2E;
  }
  .management_contents .management_details .management_details_contents .management_details_form .custom_radio {
    font: 500 12px/20px Spartan;
    color: #2E2E2E;
  }
  .management_contents .management_details .management_details_contents .management_details_form .custom_radio span {
    display: inline-block;
    padding-top: 3px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .btn:focus {
    outline: none !important;
  }
  .management_contents .management_details .management_details_contents .management_details_form .input_img {
    position: relative;
  }
  .management_contents .management_details .management_details_contents .management_details_form .input_img input {
    padding-left: 50px;
  }
  .management_contents .management_details .management_details_contents .management_details_form .input_img img {
    position: absolute;
    top: 43px;
    left: 30px;
  }
  .management_contents .management_details .footer_btn {
    display: flex;
    justify-content: space-between;
  }
  .management_contents .management_details .footer_btn .btn {
    font: 600 12px Spartan;
    border: 1px solid #9A8776;
    border-radius: 46px;
    padding: 10px 20px;
  }
  .management_contents .management_details .footer_btn .btn:focus {
    box-shadow: none !important;
  }
  .management_contents .management_details .footer_btn .btn_borderd {
    color: #9A8776;
    background-color: transparent;
  }
  .management_contents .management_details .footer_btn .btn_filled {
    color: #fff !important;
    background-color: #9A8776 !important;
  }
  
  .custom-radio .custom-control-label::before {
    background-color: #FFF !important;
    border: 1px solid #9a8776;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #9A8776 !important;
    border: 1px solid #9A8776 !important;
    box-shadow: none !important;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #9a8776 !important;
  }
  .card-box{
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
  }
  .add_discount_full_box{
    border: 1px solid #CDD0E34B;
    border-radius: 7px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px!important;
  }
  .add_discount_full_box h4{
    font-size: 12px!important;
    margin-bottom: 6px!important;
  }
  .add_discount_full_box .management_details_form{
    border:none!important;
    border-radius: 0;
    background-color: transparent!important;
    padding: 0px!important;
  }
  .add_discount_full_box select{
    border-radius: 7px!important;
  }
  .summaru_active{
    text-align: center;
    font: normal normal 600 11px/14px Spartan;
    letter-spacing: 0px;
    color: #2E8E1D;
  }
  .generate_code{
    font: normal normal 500 12px/20px Spartan;
    letter-spacing: 0px;
    color: #4377BB;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .checkbox{
    margin-left: 15px;
    margin-top: 15px;
  }
  .summaru_active_pera p{
    font: normal normal normal 12px/13px Spartan;
    letter-spacing: 0px;
    color: #747474;
  }
  label.label_block{
    display: block;
  }
  .management_contents .management_details .management_details_contents .management_details_form .input_img input {
    padding-left: 50px;
  }
  
  /* Bootstrap Checkbox Customization */
  .custom_checkbox {
    line-height: 25px !important;
  }
  
  .custom_checkbox .custom_control_input:checked ~ .custom_control_label::before {
    background-color: #9A8776;
    box-shadow: 0 0 3px #9A8776;
    width: 17px;
    height: 17px;
  }
  
  .custom_checkbox .custom_control_input ~ .custom_control_label::before {
    width: 17px;
    height: 17px;
    box-shadow: 0 0 3px #3F4544 !important;
  }
  
  .custom_checkbox .custom_control_input:checked ~ .custom_control_label::after {
    top: 2px !important;
    left: -26px !important;
    width: 22px !important;
    height: 22px !important;
  }
  
  .custom_checkbox .custom_control_input:focus ~ .custom_control_label::before {
    box-shadow: 0 0 3px #9A8776;
  }
  
  .custom_checkbox .custom_control_input:active ~ .custom_control_label::before {
    box-shadow: 0 0 3px #9A8776;
    background: rgba(154, 135, 118, 0.9);
  }
  
  .custom_checkbox .custom_control_label::before {
    border-radius: 0.25rem;
  }
  
  .custom_control_label::before {
    background-color: #fff;
  }
  
  .custom_control_label span {
    padding-left: 5px;
    padding-top: 4px;
    display: inline-block;
  }

  .gst_tax_non_editable_select :global(.ant-select-selection-item-remove) {
    display: none !important;
}

  
  /* Bootstrap Checkbox Customization End*//*# sourceMappingURL=index.css.map */