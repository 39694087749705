/* .customTable {
    width: 100%;
} */

/* Multiple ways to target dimmed rows */
.dimmedRow,
.dimmed-row,
.ant-table-row.dimmed-row,
.ant-table-row.dimmed {
    opacity: 0.5 !important;
    background-color: #f5f5f5 !important;
    pointer-events: none;
}

.dimmedRow td,
.dimmed-row td,
.ant-table-row.dimmed-row td {
    color: #999 !important;
}

/* Hover state */
.dimmedRow:hover,
.dimmed-row:hover {
    opacity: 0.5 !important;
}