/* Common styles */
.dashboard_card_title {
  font: 600 20px/30px Spartan;
  color: #2D2424;
}

.dashboard_card {
  background-color: #FFFFFF;
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 24px;
  padding: 20px;
}

.grayesh_orange h5{
  color: #C8AC92;
}

.grayesh_orange_dark h5{
  color: #BE6A4A;
}

.gray_dark h5{
  color: #677B7B;
}

.gray_lite h5{
  color: #9F9F9B;
}

/* Common styles */

.admin_management .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.admin_management .row .col-lg-2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.admin_management .row .col-lg-10 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #CDD0E34B !important;
}

.admin_management .row .col-lg-2 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.admin_management .row .col-lg-10 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #CDD0E34B !important;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}

.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}

.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2D2424;
  margin-top: 15px;
}

.management_sidebar .list_group_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}

.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}

.management_sidebar .list_group_item.active {
  background-color: transparent;
}

.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}

.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}

.management_sidebar .list_group_sub_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}

.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}

.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3F4544;
  margin-bottom: 0px !important;
}

.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3F4544;
  display: block;
}

.management_contents {
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-top-left-radius: 40px;
  padding: 30px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
}

.management_contents::-webkit-scrollbar {
  display: none;
}

.management_contents .management_header_date {
  color: #2D2424;
  font: 400 12px Spartan;
  margin-bottom: 20px;
}

.management_contents .management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.management_contents .management_header h1 {
  font: 600 20px/33px Spartan;
  color: #3F4544;
}

.management_contents .management_header h1 .management_header_welcome {
  font: 400 24px/33px Spartan;
  color: #3F4544;
  margin-left: 10px;
}
.management_contents .management_header h1 .management_header_hi{
  margin-right: 5px;
}
.management_header_contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.management_header_downloads_icon {
  margin-left: 10px;
  height: 25px;
  width: 25px;
}

.management_header_downloads_text {
  margin-left: 10px;
  font: 600 15px/18px Spartan;
  color: #2D2424;
}

.management_header_downloads_text img {
  vertical-align: inherit;
}

.management_contents .management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.management_contents .management_header h1 {
  font: 600 20px/33px Spartan;
  color: #3F4544;
}

.management_contents .management_header h1 .management_header_welcome {
  font: 400 24px/33px Spartan;
  color: #3F4544;
  margin-left: 10px;
}

.management_contents .management_header .form_control {
  display: inline;
  width: auto;
  font-weight: 600 !important;
  font-size: 12px;
  color: #2D2424;
  padding: 5px;
  height: auto;
  box-shadow: 0px 10px 20px #0000000D;
  border: 1px solid #CDD0E34B;
  border-radius: 5px;
}

.managenet_card {
  position: relative;
}

.management_contents .managenet_card .managenet_card_media {
  background-color: white;
  border-radius: 24px;
  align-items: center;
  padding: 20px;
}

.managenet_card .dashboard_revenue_card .managenet_card_media_contents:not(:last-child) .managenet_card_media {
  margin-bottom: 22px;
}

.management_contents .managenet_card .managenet_card_media .media_img {
  height: 53px;
  width: 53px;
  border-radius: 14px;
}

.management_contents .managenet_card .managenet_card_media .media_body {
  font: 500 10px/13px Spartan;
  color: #2D2424;
  margin-top: 8px;
}

.management_contents .managenet_card .managenet_card_media .media_body h5 {
  font: normal normal 600 13px/30px Spartan;
  letter-spacing: 0px;
  color: #2D2424;
  margin-bottom: 0px;
}

.last_week_report {
  font: 500 10px/10px Spartan;
  color: #379E7A;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last_week_report .bar_chart_arrow {
  height: 10px;
  width: 10px;
  vertical-align: baseline;
  margin-right: 5px;
}

.bar_chart {
  width: 22px;
  height: 15px;
  vertical-align: baseline;
  /* margin-left: 30%; */
}

.topSellingCategory p {
  margin-left: 78px;
  margin-top: -50px;
  background-color: white;
  border-radius: 24px;
  align-items: center;
  padding: 20px;
}

.topSellingCategory_card {
  width: 640px;
  height: 300px;
  background-color: white;
  border-radius: 24px;
  align-items: center;
  padding: 20px;
}

.topSellingCategory_item {
  margin-bottom: 10px;
}
.management_contents_right_slide_bar{
  position:sticky;
  top:0;
}
.right_slide_bar_sticky{
  overflow-y: scroll;
  height: 100%;
  scrollbar-color: #e1e1dfd6 rgba(255, 255, 255, 0); /* thumb and track color */
  scrollbar-width: thin;
}   
.right_slide_bar_sticky::-webkit-scrollbar {
  width: 3px;
}
.right_slide_bar_sticky::-webkit-scrollbar-thumb {
  background-color: #e1e1dfd6; 
}
/* Search bar */
.management_header_search {
  position: relative;
  margin-bottom: 30px;
}

.management_header_search .management_header_search_bar {
  border: none;
  width: 100%;
  max-width: 450px;
  height: 40px;
  background: #F0F0F4;
  border-radius: 7px;
  padding: 15px 10px 10px 40px;
  font: 500 14px/28px Spartan;
}

.management_header_search .search_icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.export_card_box {
  position: absolute;
  right: 3px;
  top: 53px;
}

/* Search bar end */

/* Revenue */
.revenue {
  box-shadow: 0px 10px 20px #0000000d;
  border: 1px solid #CDD0E34B;
  border-radius: 24px;
  background-color: #ffffff;
  padding: 20px 10px;
  /* overflow-x: scroll; */
}

.revenue::-webkit-scrollbar {
  display: none;
}

.revenue_title {
  font: 600 16px/32px Spartan;
  color: #2D2424;
  margin-left: 10px;
  margin-bottom: 10px;
}

.revenue_value {
  font: 600 28px/30px Spartan;
  color: #5C3D2E;
  margin-left: 10px;
  margin-bottom: 0px;
}

/* Revenue End*/

/* Overview Card*/
.overview_card {
  border: 1px solid #F0F1F7;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
}

.overview_card_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.overview_card_title {
  font: 500 10px/13px Spartan;
  color: #000000;
  margin-bottom: 0px;
}

.overview_card_percentage {
  border-radius: 50%;
  font: 600 8px/16px Spartan;
  width: 30px;
}

.overview_card_contents {
  font: 600 20px/30px Spartan;
}

.overview_card.grayesh_orange .overview_card_percentage {
  background-color: rgb(200 172 146 / 21%);
  color: #C8AC92;
}

.overview_card.grayesh_orange .overview_card_contents {
  color: #C8AC92;
}

.overview_card.grayesh_orange_dark .overview_card_percentage {
  background-color: rgba(190, 106, 74, 0.3);
  color: #BE6A4A;
}

.overview_card.grayesh_orange_dark .overview_card_contents {
  color: #BE6A4A;
}

.overview_card.gray_dark .overview_card_percentage {
  background-color: rgba(103, 123, 123, 0.3);
  color: #677B7B;
}

.overview_card.gray_dark .overview_card_contents {
  color: #677B7B;
}

.overview_card.gray_lite .overview_card_percentage {
  background-color: rgba(159, 159, 155, 0.3);
  color: #9F9F9B;
}

.overview_card.gray_lite .overview_card_contents {
  color: #9F9F9B;
}

/* Overview Card end*/

/* Top Selling */
.top_selling_category .top_selling_category_item {
  align-items: center;
}

.top_selling_category:not(:last-child) .top_selling_category_item {
  margin-bottom: 22px;
}

.top_selling_category_item img {
  width: 55px;
  height: 40px;
  padding: 15px 10px;
  box-sizing: content-box;
  border: 1px solid #EBEBEB;
  border-radius: 18px;
}

.top_selling_category_item h4 {
  font: 600 12px/16px Spartan;
  color: #2D2424;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.top_selling_category_item p {
  font: 500 9px/11px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}

/* Top Selling end*/

/* Most Viewed products*/
.most_viewed_products_item {
  align-items: center;
  margin-bottom: 20px;
}

.most_viewed_products_item img {
  width: 55px;
  height: 40px;
  padding: 15px 10px;
  box-sizing: content-box;
  border: 1px solid #EBEBEB;
  border-radius: 18px;
  margin-right: 10px;
}

.most_viewed_products_item h4 {
  font: 600 12px/16px Spartan;
  color: #2D2424;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.most_viewed_products_item p {
  font: 500 9px/11px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}

/* Most Viewed Products end*/

/* Most Sold products*/
.most_sold_products_item {
  align-items: center;
  margin-bottom: 20px;
}

.most_sold_products_item img {
  width: 55px;
  height: 40px;
  padding: 15px 10px;
  box-sizing: content-box;
  border: 1px solid #EBEBEB;
  border-radius: 18px;
}

.most_sold_products_item h4 {
  font: 600 12px/16px Spartan;
  color: #2D2424;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.most_sold_products_item p {
  font: 500 9px/11px Spartan;
  color: #2D2424;
  margin-bottom: 0px;
}

/* Most Sold Products end*/

/* Top Search */
.searched_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.searched_header .dashboard_card_title {
  margin-bottom: 0px !important;
}

.searched_header img {
  margin-left: 10px;
  vertical-align: baseline;
  padding-top: 2px;
}

.searched_header .see_more {
  font: 500 11px/14px Spartan;
  color: #5C3D2E;
}

.searched_header .see_more:hover {
  color: #5C3D2E !important;
}

.table {
  font: 500 11px/13px Spartan;
  color: #2D2424;
}
.last_week_report_text{
  line-height: 16px;
  margin-right: 5px;
}
.profie_pic img{
width: 105px;
height: 105px;
border:12px solid #fff;
margin: auto;
border-radius: 50%;
display: block;
margin-top: 25px;
}
.profie_user_list ul{
  padding-left: 0px;
  margin-top: 27px;
}
.profie_user_list ul li{
 color:#2D2424;
 margin-bottom: 18px;
 list-style-type: none;
}
.profie_user_list ul li a {
 font-size: 13px;
 color:#2D2424;
 line-height:16px ;
}
.profie_user_list ul li a:hover {
text-decoration: none;
}
.profie_user_list ul li a img{
  width: 32px;
  height: 32px;
  margin-right: 17px;
}
.notification_box{
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 10px;
  margin-top: 58px;
}
.notification_img_box{
  width: 46px;
  height:46px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 10px;
}
.notification_box .notification_img_box img{
  width: 40px;
  margin: auto;
  padding: 5px;
}
.notification_box .notifidetails_contents_title{
  font: normal normal normal 9px/11px Spartan;
letter-spacing: 0px;
color: #F04545;
text-transform: capitalize;
margin-bottom: 5px;
}
.notification_box .notifidetails_contents_description{
  font: normal normal 600 10px/16px Spartan;
  letter-spacing: 0px;
  color: #2D2424;
  text-transform: capitalize;
}
.notifidetails_contents_message{
  font: normal normal normal 8px/11px Spartan;
  letter-spacing: 0px;
  color: #4D4F5C;
}
@media (max-width: 1400px){
.most_viewed_products_item .mobmright{
  margin-right: 5px!important;
}
.most_viewed_products_item img {
  padding: 8px 5px;
}
}
/* Top Search end*/
@media (max-width: 1200px){
  .management_contents .managenet_card .managenet_card_media {
    padding: 8px;
  }
  .management_contents .managenet_card .managenet_card_media .media_img {
    margin-right: 5px!important;
  }
  
}