.form-control:focus {
  box-shadow: none !important;
  border-color: #cdd0e34b !important;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}
.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}
.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2d2424;
  margin-top: 15px;
}
.management_sidebar .list_group_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}
.management_sidebar .list_group_item.active {
  background-color: transparent;
}
.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}
.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}
.management_sidebar .list_group_sub_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}
.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3f4544;
  margin-bottom: 0px !important;
}
.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3f4544;
  display: block;
}

.management_contents {
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-top-left-radius: 40px;
  padding: 50px;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.management_contents_fullheight{
  min-height: calc(100vh - 0px);
  max-height: calc(100vh - 0px);
}
.management_contents .management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8e8e8;
}
.management_contents .management_header h1 {
  font: 600 20px/33px Spartan;
  color: #3f4544;
}
.management_contents .management_header h1 img {
  margin-right: 20px;
}
.management_contents .management_header .form_control {
  border: 1px solid #cdd0e34b;
  border-radius: 46px;
  display: inline;
  width: auto;
  font-weight: 600 !important;
  font-size: 12px;
  color: #2d2424;
  padding: 5px 15px;
  height: auto;
}
.management_contents .management_contents_upload span {
  display: inline-block;
  font: 600 12px Spartan;
  color: #9a8776;
  border: 1px solid #9a8776;
  border-radius: 46px;
  padding: 10px 20px;
  margin-left: 15px;
  cursor: pointer;
}
.management_contents .management_contents_upload span:last-child {
  color: #fff;
  background-color: #9a8776;
}
.management_contents .management_contents_upload span:last-child:hover {
  text-decoration: underline;
}
.management_contents .management_details h4 {
  font: 600 16px/20px Spartan;
  color: #2e2e2e;
  margin-bottom: 12px;
}
.management_contents .management_details .management_details_contents {
  margin-bottom: 25px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_contents_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_contents_header
  a {
  font: 500 12px/20px Spartan;
  color: #4377bb;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form {
  border: 1px solid #cdd0e34b;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .form_control {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  label {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .btn:focus {
  outline: none !important;
}
.management_contents
  .management_details
  .management_details_contents
  input::-moz-placeholder {
  color: #b9b9b9;
}
.management_contents
  .management_details
  .management_details_contents
  input:-ms-input-placeholder {
  color: #b9b9b9;
}
.management_contents
  .management_details
  .management_details_contents
  input::placeholder {
  color: #b9b9b9;
}

.text_red {
  color: #ea563f !important;
}

/* Bootstrap Checkbox Customization */
.custom_checkbox {
  line-height: 25px !important;
}

.custom_checkbox .custom_control_input:checked ~ .custom_control_label::before {
  background-color: #9a8776;
  box-shadow: 0 0 3px #9a8776;
  width: 15px;
  height: 15px;
}

.custom_checkbox .custom_control_input ~ .custom_control_label::before {
  width: 15px;
  height: 15px;
  box-shadow: 0 0 3px #3f4544 !important;
}

.custom_checkbox .custom_control_input:checked ~ .custom_control_label::after {
  top: 2px !important;
  left: -26px !important;
  width: 20px !important;
  height: 20px !important;
}

.custom_checkbox .custom_control_input:focus ~ .custom_control_label::before {
  box-shadow: 0 0 3px #9a8776;
}

.custom_checkbox .custom_control_input:active ~ .custom_control_label::before {
  box-shadow: 0 0 3px #9a8776;
  background: rgba(154, 135, 118, 0.9);
}

.custom_checkbox .custom_control_label::before {
  border-radius: 0.25rem;
}

.custom_control_label::before {
  background-color: #fff;
}

.custom_control_label span {
  padding-left: 5px;
  padding-top: 4px;
  display: inline-block;
}

.table_attributes thead .th_radio_button {
  width: 5%;
}
.table_attributes thead .th_default {
  width: 10%;
}
.table_attributes td,
.table_attributes th {
  vertical-align: middle !important;
  white-space: nowrap;
}
.table_attributes th {
  font: 600 12px/20px Spartan;
  color: #3f4544;
}

.table_attributes td .swatch {
  background-color: #ece9e9;
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font: 500 14px/35px Spartan;
  letter-spacing: 0px;
  color: #3f4544;
  padding: 10px 25px;
  height: 55px;
  display: inline-block;
  vertical-align: middle;
}

.square_box {
  width: 45px;
  background: rgb(15, 15, 15);
  height: 53px !important;
  border-radius: 28px;
  width: 54px;
}

/* Bootstrap Checkbox Customization End*/ /*# sourceMappingURL=index.css.map */
