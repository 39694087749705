.form-control:focus {
  box-shadow: none !important;
  border-color: #CDD0E34B !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}
.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}
.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2D2424;
  margin-top: 15px;
}
.management_sidebar .list_group_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}
.management_sidebar .list_group_item.active {
  background-color: transparent;
}
.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}
.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}
.management_sidebar .list_group_sub_item:hover {
  color: #9A8776;
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}
.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3F4544;
  margin-bottom: 0px !important;
}
.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3F4544;
  display: block;
}

.management_contents {
  background: transparent linear-gradient(215deg, #F7F5F1 0%, #F4F6FA 52%, #F4F1F2 100%);
  border-top-left-radius: 40px;
  padding: 50px;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.management_contents .management_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E8E8E8;
}
.management_contents .management_header h1 {
  font: 600 20px/33px Spartan;
  color: #3F4544;
}
.management_contents .management_header h1 img {
  margin-right: 20px;
}
.management_contents .management_header .form_control {
  border: 1px solid #CDD0E34B;
  border-radius: 46px;
  display: inline;
  width: auto;
  font-weight: 600 !important;
  font-size: 12px;
  color: #2D2424;
  padding: 5px 15px;
  height: auto;
}
.management_contents .management_contents_upload span {
  display: inline-block;
  font: 600 12px Spartan;
  color: #9A8776;
  border: 1px solid #9A8776;
  border-radius: 46px;
  padding: 8px 16px;
  cursor: pointer;
}
.management_contents .management_contents_upload span:last-child {
  color: #fff;
  background-color: #9A8776;
}
.management_contents .management_contents_upload span:last-child:hover {
  text-decoration: underline;
}
.management_contents .management_details h4 {
  font: 600 16px/20px Spartan;
  color: #2E2E2E;
  margin-bottom: 12px;
}
.management_contents .management_details .management_details_contents {
  margin-bottom: 25px;
}
.management_contents .management_details .management_details_contents .management_details_contents_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.management_contents .management_details .management_details_contents .management_details_contents_header a {
  font: 500 12px/20px Spartan;
  color: #4377BB;
}
.management_contents .management_details .management_details_contents .management_details_form {
  border: 1px solid #CDD0E34B;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
}
.management_contents .management_details .management_details_contents .form_control {
  border: 1px solid #CDD0E3;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
}
.management_contents .management_details .management_details_contents label {
  font: 500 12px/20px Spartan;
  color: #2E2E2E;
  text-indent: 6px;
}
.management_contents .management_details .management_details_contents .btn:focus {
  outline: none !important;
}
.management_contents .management_details .management_details_contents input::-moz-placeholder {
  color: #B9B9B9;
}
.management_contents .management_details .management_details_contents input:-ms-input-placeholder {
  color: #B9B9B9;
}
.management_contents .management_details .management_details_contents input::placeholder {
  color: #B9B9B9;
}

.text_red {
  color: #EA563F !important;
}

/* Bootstrap Checkbox Customization */
.custom_checkbox {
  line-height: 25px !important;
}

.custom_checkbox .custom_control_input:checked ~ .custom_control_label::before {
  background-color: #9A8776;
  box-shadow: 0 0 3px #9A8776;
  width: 15px;
  height: 15px;
}

.custom_checkbox .custom_control_input ~ .custom_control_label::before {
  width: 15px;
  height: 15px;
  box-shadow: 0 0 3px #3F4544 !important;
}

.custom_checkbox .custom_control_input:checked ~ .custom_control_label::after {
  top: 2px !important;
  left: -26px !important;
  width: 20px !important;
  height: 20px !important;
}

.custom_checkbox .custom_control_input:focus ~ .custom_control_label::before {
  box-shadow: 0 0 3px #9A8776;
}

.custom_checkbox .custom_control_input:active ~ .custom_control_label::before {
  box-shadow: 0 0 3px #9A8776;
  background: rgba(154, 135, 118, 0.9);
}

.custom_checkbox .custom_control_label::before {
  border-radius: 0.25rem;
}

.custom_control_label::before {
  background-color: #fff;
}

.custom_control_label span {
  padding-left: 5px;
  padding-top: 4px;
  display: inline-block;
}

/* Bootstrap Checkbox Customization End*/
.management_toggle .label_toggle {
  background-color: white;
  vertical-align: middle;
  border-radius: 15px;
  padding: 8px 16px;
  display: inline-block;
}
.management_toggle .label_toggle .label_text {
  display: inline-block;
  font: 500 12px/20px Spartan;
  letter-spacing: 0px;
  color: #2E2E2E;
  margin-bottom: 0px !important;
}
.management_toggle .btn_save {
  display: inline-block;
  font: 600 12px Spartan;
  color: #fff;
  background-color: #9A8776;
  border: 1px solid #9A8776;
  border-radius: 46px;
  padding: 10px 20px;
  margin-left: 15px;
  cursor: pointer;
}
.management_toggle .btn_save:hover {
  text-decoration: underline;
}

.onoffswitch {
  position: relative;
  width: 90px;
  margin-left: 10px;
}
.onoffswitch.yes_no {
  width: 60px;
  display: inline-block;
  vertical-align: middle;
}
.onoffswitch.yes_no .onoffswitch_inner:before {
  content: "Yes" !important;
}
.onoffswitch.yes_no .onoffswitch_inner:after {
  content: "No" !important;
}
.onoffswitch.yes_no .onoffswitch_switch {
  right: 40px !important;
}

.onoffswitch_checkbox {
  display: none;
}

.onoffswitch_label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #2E8E1D;
  border-radius: 16px;
  margin-bottom: 0 !important;
}

.onoffswitch_inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch_inner:before, .onoffswitch_inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  font: 500 12px/24px Spartan;
  box-sizing: border-box;
}
.onoffswitch_inner:before {
  content: "Active";
  padding-left: 10px;
  background-color: transparent !important;
  color: #2E8E1D;
}
.onoffswitch_inner:after {
  content: "Inactive";
  padding-right: 10px;
  background-color: transparent !important;
  color: #2E8E1D;
  text-align: right;
}

.onoffswitch_switch {
  display: block;
  width: 15px;
  /* margin: auto; */
  background: #2E8E1D;
  border: 1px solid #2E8E1D;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  bottom: 0;
  right: 65px;
  transition: all 0.3s ease-in 0s;
  height: 15px;
}

.onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_inner {
  margin-left: 0;
}
.onoffswitch_checkbox:checked + .onoffswitch_label .onoffswitch_switch {
  right: 5px !important;
}

.add_image_card {
  min-height: 160px;
  max-height: 160px;
  overflow: auto;
}
.add_image_card .category_image {
  display: inline-block;
  position: relative;
}
.add_image_card .category_image .added_image {
  vertical-align: baseline;
  width: 107px;
  height: 107px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #CDD0E3;
  border-radius: 10px;
  padding: 5px;
}
.add_image_card .category_image .close_icon {
  position: absolute;
  right: 7px;
  top: -4px;
}
.add_image_card.upload_empty {
  text-align: center;
  padding: 25px;
}
.add_image_card.upload_empty .upload_img {
  padding: 20px 40px;
  width: 210px;
}
.add_image_card.upload_empty .upload_img img {
  height: 53px;
  width: 81px;
  margin-bottom: 20px;
}
.add_image_card.upload_empty .upload_img .btn {
  font-size: 15px;
  margin-bottom: 15px;
}
.add_image_card.upload_empty .upload_img span {
  font: 500 15px/26px Spartan;
}
.add_image_card textarea {
  width: 100%;
  border: none !important;
}
.add_image_card textarea:focus-visible {
  outline: none !important;
}
.add_image_card .upload_img {
  padding: 10px;
  border: 1px dashed #CDD0E3;
  border-radius: 10px;
  /* width: 140px; */
  height: auto;
  display: inline-block;
}
.add_image_card .upload_img img {
  margin-bottom: 10px;
  width: 54px;
  height: 36px;
}
.add_image_card .upload_img .btn {
  background-color: transparent;
  border: 1px solid #AFA7A0;
  font-size: 12px;
  margin-bottom: 10px;
  color: #9A8776;
  border-radius: 5px;
}
.add_image_card .upload_img .btn:hover {
  color: #fff;
  background-color: #9A8776;
}
.add_image_card .upload_img span {
  font: 500 10px/17px Spartan;
  color: #B9B9B9;
  display: block;
}/*# sourceMappingURL=index.css.map */

.edit_category_update_user_date p{
  white-space: nowrap;
  font: normal normal 600 10px/20px Spartan;
  letter-spacing: 0px;
  color:#B9B9B9;
  margin-bottom: 3px;
  margin-left: 15px;
}
.edit_category_update_user_date span{
  color:#000;
}