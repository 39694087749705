.manage_lineHeight{
    line-height: 0;
}

.manage_delete_text{
    color:lightgrey;
    font-size:12px
}

.popup_image_size{
    width: 80px;
    height: auto;
}