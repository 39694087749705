/* .management_details .management_details_contents .management_details_form {
    border: 1px solid #CDD0E34B;
    border-radius: 15px;
    background-color: #fff;
    padding: 20px;
}

.management_details_contents {
    margin-bottom: 30px;
}

.pt_12 {
    padding-top: 12px !important;
}

.form_control:focus {
    box-shadow: none !important;
    border-color: #CDD0E34B !important;
}

.management_details .management_details_contents .management_details_form .form_control {
    border: 1px solid #CDD0E3;
    border-radius: 10px;
    font: 500 14px/20px Spartan;
}

.management_details .management_details_contents .management_details_form label {
    font: 500 12px/20px Spartan;
    color: #2E2E2E;
}

.management_details .management_details_contents .management_details_form .mobile_number .form_control {
    position: relative;
    padding-left: 100px;
}

.management_details .management_details_contents .management_details_form .mobile_number .mobile_number_flag {
    position: absolute;
    top: 40px;
    left: 30px;
}

.management_details .management_details_contents .management_details_form .mobile_number .mobile_number_flag img {
    border-right: 1px solid gray;
    padding-right: 13px;
}

.management_details .management_details_contents .management_details_form .mobile_number .mobile_number_flag span {
    vertical-align: middle;
    padding-left: 10px;
    font: 500 14px/20px Spartan;
}

.management_details .management_details_contents .management_details_form .reset_password {
    width: 100%;
    background-color: transparent;
    font: 500 16px/20px Spartan;
    letter-spacing: 0px;
    color: #9A8776;
    border: 1px solid #9A8776;
    padding: 12px 24px;
    border-radius: 10px;
    margin-top: 12px;
} */

/* .admin_management .row_margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .admin_management .row_margin .col-lg-2,
  .admin_management .row_margin .col-lg-10 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */

.add_image_card {
  min-height: 260px;
  max-height: 260px;
  overflow: auto;
}

.upload_img_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload_img_header a {
  font: 500 12px/20px Spartan;
  color: #4377bb;
}

.upload_img {
  padding: 10px;
  border: 1px dashed #cdd0e3;
  border-radius: 10px;
  width: 140px;
  height: auto;
  display: inline-block;
}

.upload_img img {
  margin-bottom: 10px;
  width: 54px;
  height: 36px;
}

.upload_img .btn {
  background-color: transparent;
  border: 1px solid #afa7a0;
  font-size: 12px;
  margin-bottom: 10px;
  color: #9a8776;
  border-radius: 5px;
}

.upload_img span {
  font: 500 10px/17px Spartan;
  color: #b9b9b9;
  display: block;
}

.upload_img .btn {
  background-color: transparent;
  border: 1px solid #afa7a0;
  font-size: 12px;
  margin-bottom: 10px;
  color: #9a8776;
  border-radius: 5px;
}

.form_control:focus {
  box-shadow: none !important;
  border-color: #cdd0e34b !important;
}

.management_header_logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: #fff;
}
.management_header_logo .management_info_img img {
  margin-left: 10px;
  height: 32px;
  width: 32px;
  box-shadow: 0px 5px 15px #0000001a;
  border-radius: 50%;
}

.management_sidebar .list_group {
  margin-top: 40px;
  margin-bottom: 40px;
}
.management_sidebar .list_group_item {
  border: none !important;
  padding: 8px 0px;
  font: 500 14px Spartan;
  color: #2d2424;
  margin-top: 15px;
}
.management_sidebar .list_group_item.active {
  background-color: transparent;
}
.management_sidebar .list_group_item.active img {
  box-sizing: content-box;
  padding: 10px;
  background-color: #f6efe7;
  border-radius: 4px;
  margin-left: 15px;
  margin-right: 15px;
}
.management_sidebar .list_group_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .list_group_item img {
  height: 16px;
  width: 16px;
  margin: 0px 25px;
}
.management_sidebar .list_group_sub_item {
  padding-left: 90px !important;
  border: none !important;
  padding-top: 8px;
  padding-bottom: 8px;
  font: 500 14px Spartan;
}
.management_sidebar .list_group_sub_item:hover {
  color: #9a8776;
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-right: 5px solid #9a8776 !important;
}
.management_sidebar .admin_sidebar_footer {
  margin: 0px 25px;
}
.management_sidebar .admin_sidebar_footer h4 {
  font: 700 12px/30px Spartan;
  color: #3f4544;
  margin-bottom: 0px !important;
}
.management_sidebar .admin_sidebar_footer span {
  font: 500 8px/14px Spartan;
  color: #3f4544;
  display: block;
}

.management_contents {
  background: transparent
    linear-gradient(215deg, #f7f5f1 0%, #f4f6fa 52%, #f4f1f2 100%);
  border-top-left-radius: 40px;
  padding: 50px;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.management_contents .management_header {
  padding-bottom: 40px;
}
.management_contents .management_header h1 {
  font: 600 20px/33px Spartan;
  color: #3f4544;
}
.management_contents .management_header h1 img {
  margin-right: 20px;
}
.management_contents .management_header .form_control {
  border: 1px solid #cdd0e34b;
  border-radius: 46px;
  display: inline;
  width: auto;
  font-weight: 600 !important;
  font-size: 12px;
  color: #2d2424;
  padding: 5px 15px;
  height: auto;
}
.management_contents .management_contents_upload span {
  display: inline-block;
  font: 600 12px Spartan;
  color: #9a8776;
  border: 1px solid #9a8776;
  border-radius: 46px;
  padding: 10px 20px;
  margin-left: 15px;
}
.management_contents .management_contents_upload span:last-child {
  color: #fff;
  background-color: #9a8776;
}
.management_contents .management_details .management_details_contents {
  margin-bottom: 25px;
}
.management_contents .management_details .management_details_contents h4 {
  font: 600 16px/20px Spartan;
  color: #2e2e2e;
  margin-bottom: 12px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form {
  border: 1px solid #cdd0e34b;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .form_control {
  border: 1px solid #cdd0e3;
  border-radius: 10px;
  font: 500 14px/20px Spartan;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  label {
  font: 500 12px/20px Spartan;
  color: #2e2e2e;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .mobile_number
  .form_control {
  position: relative;
  padding-left: 100px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .mobile_number
  .mobile_number_flag {
  position: absolute;
  top: 43px;
  left: 30px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .mobile_number
  .mobile_number_flag
  img {
  border-right: 1px solid gray;
  padding-right: 13px;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .mobile_number
  .mobile_number_flag
  span {
  vertical-align: middle;
  padding-left: 10px;
  font: 500 14px/20px Spartan;
}
.management_contents
  .management_details
  .management_details_contents
  .management_details_form
  .reset_password {
  width: 100%;
  background-color: transparent;
  font: 500 16px/20px Spartan;
  letter-spacing: 0px;
  color: #9a8776;
  border: 1px solid #9a8776;
  padding: 12px 24px;
  border-radius: 10px;
  margin-top: 12px;
} /*# sourceMappingURL=index.css.map */
