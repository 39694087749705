.form_control:focus {
    border: 1px solid #DEDEDE !important;
    box-shadow: none !important;
  }
  
  .btn_login {
    width: 100%;
    height: 49px;
    font: 400 16px/20px Spartan;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 16px 32px;
    background-color: #A39486;
    border-radius: 3px !important;
    margin-bottom: 3px;
    text-align: center;
    opacity: 1;
  }
  .btn_login:focus {
    box-shadow: none !important;
  }
  
  .login_bg {
    background-image: url("../assets/loginii.png");
    min-height: 100vh;
    padding: 20px 80px;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }
  
  .login_banner_img {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    width: 100%;
  }
  .login_banner_img img {
    width: 90%;
    height: auto;
    margin-top: 5%;
  }
  
  .login {
    /* background-color: #fff; */
    width: 100%;
    max-width: 450px;
    padding: 55px 55px 28px 55px;
    border-radius: 10px;
    /* border: 1px solid #D3CBCB; */
    border-radius: 17px;
    margin: 12% auto 0;
    margin-left: 100%;
  }
  .login .logo_img {
    margin-bottom: 35px;
    text-align: center;
  }
  .login .logo_img img {
    width: 200px;
    height: 65px;
  }
  .login .title_text {
    font: bold 36px/34px Cormorant Garamond;
    color: #9A8776;
    margin-bottom: 15px;
  }
  .login .welcome_text {
    font: 80 Spartan;
    color: #fbffff;
    margin-bottom: 15px;
    margin-left: 27px;
  }
  .login .welcome_text_pass {
    
    transform: matrix(1, 0, 0, 1, 0, 0);
    text-align: center;
    font: normal normal normal 16px/28px Spartan;
    letter-spacing: 0px;
    color: #F6F7F8;
    opacity: 1;
  }
  .login .welcome_text_welcome{
    font: Arial;
    color: #fbffff;
    margin-bottom: 15px;
    margin-left: 50px;
    font-size: 1.5rem;
  }

  .login .form_group {
    margin-bottom: 20px !important;
  }
  .login .form_control {
    border: 1px solid #DEDEDE;
    border-radius: 3px;
    background-color: #F6F7F8;
    height: 50px;
    margin-bottom: -3px;
  }
  .login .form_control::-moz-placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .form_control:-ms-input-placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .form_control::placeholder {
    font: 500 12px/16px Spartan;
    color: #9A9A9A;
  }
  .login .error_text {
    font: 500 12px/16px Spartan;
    color: #dc3545;
    visibility: hidden;
  }
  .error{
    color: #FF7575;
    font: 500 13px/19px "Spartan";
    text-align: end;
    display: block;
}
  .login .error_text.active {
    visibility: visible;
  }
  .login .password_icon {
    position: relative;
  }
  .login .password_icon img {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .login .password_icon input {
    padding-right: 45px;
  }
  .login .forgot_password {
    margin-bottom: 10px;
    text-align: right;
    color: #fdfdfc;
  }
  .login .forgot_password a {
    font: 500 11px/16px Spartan;
    color: #fdfdfc;
  }
  @media (max-width: 991px) {
    .btn_login {
      font: 400 14px/20px Spartan;
      padding: 10px 20px;
      margin-bottom: 1px;
    }
  
    .login_banner_img {
      display: none;
    }
  
    .login_bg {
      padding: 20px;
    }
  
    .login {
      padding: 30px;
      width: 100%;
      max-width: 400px;
      margin: 10% auto 0;
    }
    .login .logo_img {
      margin-bottom: 20px;
      text-align: center;
    }
    .login .logo_img img {
      width: 180px;
      height: 55px;
    }
    .login .title_text {
      font: bold 28px/26px Cormorant Garamond;
      margin-bottom: 10px;
    }
  
    .login .form_group {
      margin-bottom: 5px !important;
    }
    .login .form_control {
      height: 40px;
    }
    .login .error_text {
      font: 500 11px/16px Spartan;
    }
    .login .password_icon img {
      top: 15px;
      right: 15px;
    }
    .login .password_icon input {
      padding-right: 35px;
    }
    .login .forgot_password {
      margin-bottom: 5px;
    }
    .login .forgot_password a {
      font: 500 11px/16px Spartan;
      color: #9A8776;
    }}
 
  .error_text.active {
    visibility: visible;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .login {
      padding: 25px 25px 18px 25px;
    }
  }/*# sourceMappingURL=index.css.map */